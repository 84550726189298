







































































import Vue from "vue";
import Component from "vue-class-component";
import { hexString2uint8Array } from "../../utils/HexTool";

@Component({})
export default class ControlPanel extends Vue {
  valid = false;
  isLoading = false;

  pictureBase64Data = "../../assets/home_game_bg_killer.jpg";

  cookieRules = [
    (v: any) => !!v || "Title is required",
    (v: string | any[]) =>
      (v && v.length <= 2000) || "Title must be less than 2000 characters",
  ];

  form = {
    cookie: "",
    clearance: "",
  };

  commitForm() {
    const _this = this;
    _this.isLoading = true;
    (_this.$refs as any).form.validate();
    _this
      .$axios({
        url: "/api/v1/system/set/picture/cookie",
        method: "put",
        data: this.form,
      })
      .then(function (response) {
        _this.isLoading = false;
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
        } else {
          _this.$toast.error(response.data.message + ":" + response.data.data);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  getRandomPicture() {
    let _this = this;
    _this
      .$axios({
        url: "/picture/random",
        method: "get",
      })
      .then(function (response) {
        _this.isLoading = false;
        if (response.data.code === 0) {
          //base64js.toByteArray
          let bytes = hexString2uint8Array(response.data.data);
          let blob = new Blob([bytes], {
            type: "image/jpg",
          });
          var Fr = new FileReader();
          Fr.readAsDataURL(blob);
          Fr.onload = (event: any) => {
            //这个就是转换为的base64图片地址
            console.log(event.target.result);
            _this.pictureBase64Data = event.target.result;
          };
        } else {
          _this.$toast.error(response.data.message + ":" + response.data.data);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }
}
